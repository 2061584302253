<template>
  <div class="px-10">
    <div class="text-h4 primary--text">
      เลือก พ.ร.บ. ประกันที่จะจ่ายในรอบนี้
    </div>
    <v-toolbar dense flat rounded outlined class="my-5 pt-3 pb-15">
      <div style="width: 300px">
        <v-select
          label="ชื่อ พ.ร.บ. และประกันภัย"
          v-model="filter.sub_category_id"
          :items="sub_category"
          item-value="_id"
          item-text="name"
          outlined
          rounded
          dense
          hide-details
        />
      </div>

      <v-spacer></v-spacer>

      <div style="width: 200px">
        <DatePicker
          label="วันที่"
          :value="filter.start_date"
          @onChange="(val) => (filter.start_date = val)"
          hide-details
          outlined
          rounded
          dense
        />
      </div>

      <v-spacer></v-spacer>

      <div style="width: 200px">
        <DatePicker
          label="ถึงวันที่"
          :value="filter.end_date"
          @onChange="(val) => (filter.end_date = val)"
          hide-details
          outlined
          rounded
          dense
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn color="error" outlined small class="ml-5 px-10" @click="moveBack()"
        >ย้อนกลับ</v-btn
      >
      <v-btn
        color="primary"
        outlined
        small
        class="ml-5 px-10"
        @click="getData()"
        >ค้นหา</v-btn
      >
      <v-btn
        color="primary"
        small
        class="ml-5"
        @click="createExpenseInsurance()"
      >
        <v-icon left small>mdi-plus</v-icon>สร้างรายจ่าย
      </v-btn>
    </v-toolbar>
    <p>จำนวนที่เลือก {{ selected.length }}</p>
    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :search="search"
      :loading="loading"
      sort-by="col_a"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      show-select
      item-key="_id"
      v-model="selected"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ formatDate(item.date)}}
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ parseFloat(item.price).toFixed(2) | numeral('0,0.00') }}
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';
export default {
  components: {
    DatePicker,
  },
  data: () => ({
    search: '',
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      { text: 'ลำดับ', value: '_id', filterable: false },
      { text: 'วันที่', value: 'date' },
      { text: 'ทะเบียน', value: 'car_plate', filterable: false },
      { text: 'จังหวัด', value: 'car_province' },
      { text: 'ชื่อสินค้า', value: 'product_name', filterable: false },
      { text: 'สาขา', value: 'branch_name', filterable: false },
      { text: 'เบี้ยรวม.', value: 'price', filterable: false },
    ],
    filter: {
      branch_id: '',
      item_per_page: 10,
      page: 1,
      sub_category_id: '',
      start_date: moment().add(-30, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
    sub_category: [],
    list: [],
  }),

  created() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getSubCategoryList();
  },

  methods: {
    createExpenseInsurance() {
      if (!this.selected.length) {
        this.$alertServerError({ title: 'ยังไม่ได้เลือกข้อมูล' });
        return;
      }
      this.$router
        .push({
          name: 'expenses-insurance-current-create',
          params: { process_array: this.selected },
        })
        .catch(() => {});
    },
    moveBack(item) {
      this.$router.push({ name: 'expenses-insurance' });
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      console.log(this.filter);
      await this.$axios
        .post(`${this.$baseUrl}/expense/get_insurance_bill`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
          console.log(this.list);
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async getSubCategoryList() {
      let filter = {
        category_id: 'PC1',
      };
      let body = {
        token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense_category/get_sub_category_list`, body)
        .then(async (res) => {
          for (var i = 0; i < res.result.length; i++) {
            this.sub_category.push(res.result[i]);
          }
          this.filter.sub_category_id = this.sub_category[0]._id;
        });
      this.getData();
    },
    // async getSubCategoryList() {
    //   let body = {
    //     token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
    //   };
    //   await this.$axios.post(`${this.$baseUrl}/expense/get_insurance_sub_category_list`, body)
    //     .then(async (res) => {
    //       for(var i = 0; i < res.result.length; i++){
    //         this.sub_category.push(res.result[i])
    //       }
    //       this.filter.sub_category_id = this.sub_category[0]._id
    //     });
    //   this.getData()
    // },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
  },
};
</script>
